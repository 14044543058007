import { Address } from 'wagmi';

import { TTradeToken } from '@/types';

export const TRADE_TOKENS: TTradeToken = {
  [process.env.NEXT_PUBLIC_BTC ?? '']: 'BTC',
  [process.env.NEXT_PUBLIC_ETH ?? '']: 'ETH',
};

export const ALL_TOKENS: TTradeToken = {
  [process.env.NEXT_PUBLIC_BTC ?? '']: 'BTC',
  [process.env.NEXT_PUBLIC_ETH ?? '']: 'ETH',
  [process.env.NEXT_PUBLIC_USDT ?? '']: 'USDT',
};

export const APPROVE_TOKENS = {
  BTC: process.env.NEXT_PUBLIC_BTC,
  ETH: process.env.NEXT_PUBLIC_ETH,
  USDT: process.env.NEXT_PUBLIC_USDT,
};

export const POOL_TOKENS: TTradeToken = {
  [process.env.NEXT_PUBLIC_BTC ?? '']: 'BTC',
  [process.env.NEXT_PUBLIC_ETH ?? '']: 'ETH',
  [process.env.NEXT_PUBLIC_USDT ?? '']: 'USDT',
};

export const USDT = process.env.NEXT_PUBLIC_USDT as Address;

export const TOKENS_COLOR = {
  BTC: '#FFA800',
  ETH: '#0985F6',
  USDT: '#23B8BA',
};

export const TOKENS_DECIMALS = {
  BTC: process.env.NEXT_PUBLIC_BTC_DECIMALS ? Number(process.env.NEXT_PUBLIC_BTC_DECIMALS) : 8,
  ETH: process.env.NEXT_PUBLIC_ETH_DECIMALS ? Number(process.env.NEXT_PUBLIC_ETH_DECIMALS) : 18,
  USDT: process.env.NEXT_PUBLIC_USDT_DECIMALS ? Number(process.env.NEXT_PUBLIC_USDT_DECIMALS) : 6,
  MLP: process.env.NEXT_PUBLIC_MLP_DECIMALS ? Number(process.env.NEXT_PUBLIC_MLP_DECIMALS) : 18,
};

export const PRICE_DECIMALS = process.env.NEXT_PUBLIC_PRICE_DECIMALS
  ? Number(process.env.NEXT_PUBLIC_PRICE_DECIMALS)
  : 30;

export const collateralTokensArr = [
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_USDT,
  process.env.NEXT_PUBLIC_USDT,
  process.env.NEXT_PUBLIC_ETH,
  process.env.NEXT_PUBLIC_ETH,
  process.env.NEXT_PUBLIC_USDT,
  process.env.NEXT_PUBLIC_USDT,
];

export const indexTokensArr = [
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_BTC,
  process.env.NEXT_PUBLIC_ETH,
  process.env.NEXT_PUBLIC_ETH,
  process.env.NEXT_PUBLIC_ETH,
  process.env.NEXT_PUBLIC_ETH,
];

export const isLongArr = [true, true, false, false, true, true, false, false];
export const isTokenBasedArr = [true, false, true, false, true, false, true, false];
