import { NextUIProvider } from '@nextui-org/react';
import { AppProps } from 'next/app';
import Head from 'next/head';
import Script from 'next/script';
import { appWithTranslation, useTranslation } from 'next-i18next';
import { Toaster } from 'react-hot-toast';
import { createPublicClient, http } from 'viem';
import { createConfig, WagmiConfig } from 'wagmi';

import '@/styles/globals.css';
import '@/styles/trade.css';
import '@/styles/dashboard.css';
import '@/styles/invite.css';

import { CHAIN } from '@/consts';

const config = createConfig({
  autoConnect: true,
  publicClient: createPublicClient({
    chain: CHAIN,
    transport: http(),
  }),
});

const MyApp = ({ Component, pageProps }: AppProps) => {
  const { t } = useTranslation(['common']);
  return (
    <NextUIProvider>
      {process.env.NEXT_PUBLIC_DEBUG === 'true' && (
        <Script
          src="https://cdn.jsdelivr.net/npm/eruda"
          onLoad={() => {
            (window as any).eruda.init();
          }}
        />
      )}
      <Script src="https://lf1-cdn-tos.bytegoofy.com/obj/iconpark/svg_31777_48.a649b9c3ac034031413fe36d8e63340b.js" />
      <Head>
        <title>{t('perpetual-contract')}</title>
        <meta
          name="viewport"
          content="viewport-fit=cover,width=device-width, initial-scale=1.0, minimum-scale=1.0, maximum-scale=1.0, user-scalable=no"
        />
      </Head>
      <WagmiConfig config={config}>
        <Component {...pageProps} />
        <Toaster
          position="top-center"
          reverseOrder={false}
          gutter={8}
          containerStyle={{
            top: 30,
          }}
          toastOptions={{
            // Define default options
            className: 'toast',
            duration: 5000,
            style: {
              background: '#3E414A',
              color: '#fff',
              fontSize: '12px',
            },
          }}
        />
      </WagmiConfig>
    </NextUIProvider>
  );
};

export default appWithTranslation(MyApp);
