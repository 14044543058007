export const CHART_PERIODS = {
  '15m': 60 * 15,
  '1h': 60 * 60,
  '4h': 60 * 60 * 4,
  '1d': 60 * 60 * 24,
  '1w': 60 * 60 * 24 * 7,
  '1s': 1,
  '1m': 60,
  '3m': 60 * 3,
  '5m': 60 * 5,
  '30m': 60 * 30,
  '2h': 60 * 60 * 2,
  '6h': 60 * 60 * 6,
  '12h': 60 * 60 * 12,
  '2d': 60 * 60 * 24 * 2,
  '3d': 60 * 60 * 24 * 3,
  '5d': 60 * 60 * 24 * 5,
};

export const getDealData = () => {
  const a = ['18:34:05', 'Sell', '19,409.5', 0];
  const b = ['18:34:05', 'Buy', '19,409.5', 0.1];
  const tmp = [];
  for (let i = 0; i < 10; i++) {
    tmp.push(a);
  }
  for (let j = 0; j < 10; j++) {
    tmp.push(b);
  }
  return tmp;
};

export const KLINE_SELECT_TAG = ['15minutes', '1hour', '4hours', 'day', 'week'];

export const SHOW_MORE_TIMES_PERIODS = [
  'minutes',
  '1sec',
  '1min',
  '3min',
  '5min',
  '30min',
  '2hours',
  '6hours',
  '12hours',
  '2days',
  '3days',
  '5days',
  '1week',
  '3months',
];

export const getFakeData = () => {
  const d = [0, 19409.5, 19, 409.5, 0];
  const tmp = [];
  for (let i = 0; i < 20; i++) {
    tmp.push(d);
  }
  return tmp;
};
