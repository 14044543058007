export const CHAIN = {
  id: process.env.NEXT_PUBLIC_CHAIN_ID ? Number(process.env.NEXT_PUBLIC_CHAIN_ID) : 1,
  name: process.env.NEXT_PUBLIC_CHAIN_NAME || '',
  network: process.env.NEXT_PUBLIC_CHAIN_NETWORK || '',
  nativeCurrency: {
    name: process.env.NEXT_PUBLIC_CHAIN_CURRENCY_NAME || '',
    symbol: process.env.NEXT_PUBLIC_CHAIN_CURRENCY_SYMBOL || 'ETH',
    decimals: process.env.NEXT_PUBLIC_CHAIN_CURRENCY_DECIMALS
      ? Number(process.env.NEXT_PUBLIC_CHAIN_CURRENCY_DECIMALS)
      : 18,
  },
  rpcUrls: {
    default: {
      http: [process.env.NEXT_PUBLIC_CHAIN_RPC_HTTPS || ''],
      webSocket: [process.env.NEXT_PUBLIC_CHAIN_RPC_WSS || ''],
    },
    public: {
      http: [process.env.NEXT_PUBLIC_CHAIN_RPC_HTTPS || ''],
      webSocket: [process.env.NEXT_PUBLIC_CHAIN_RPC_WSS || ''],
    },
  },
};
